export const TEACHER_ALLOWED_MISSED_CLASS = 4
export const DEFAULT_TRIAL_COURSE_ID = 1
export * from '../api/config/route'
export * from './date-time'
export * from './status'
export * from './common'
export * from './memo'
export * from './student-level'
export * from './curriculum'
export * from './wallet'
export * from './role'
export * from './salary'
export * from './gender'
export * from './customer'
export * from './reports'
